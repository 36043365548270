@font-face {
  font-family: 'Neutraface Condensed Bold Alt';
  src: local('Neutraface Condensed Bold Alt'), url(./fonts/NeutrafaceCondensedBoldAlt/NeutrafaceCondensedBoldAlt.otf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Neutraface Condensed Bold Alt', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Neutraface, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


